/**
 *
 * @param url - The URL to fetch the data from
 * @returns The data from the URL
 */
export const fetcher = async <T>(url: string, init?: RequestInit): Promise<T> => {
    // add cache: 'no-store' to the request to prevent caching
    const response = await fetch(url, { ...init, cache: 'no-store' });

    if (!response.ok) {
        throw new Error(
            `${response.statusText} - ${response.status.toString()} An error occurred while fetching the data.`,
        );
    }

    return response.json() as Promise<T>;
};
