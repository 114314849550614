import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/apps/in2event-staff/src/hooks/session-provider.tsx");
;
import(/* webpackMode: "eager" */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_qojyjfqbobvbxgm7plbjejjp4i/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/apps/in2event-staff/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/packages/in2event-components/src/components/index.ts");
;
import(/* webpackMode: "eager" */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/packages/next/src/i18n/client.ts");
;
import(/* webpackMode: "eager" */ "/Users/janithalakshan/Documents/Workspace/in2e-DEV/in2event-front-end/packages/next/src/i18n/locale-provider.tsx");
