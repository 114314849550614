import { ClientResponse } from '@i2e/components';
import useSWR from 'swr';

import { fetcher } from '@/lib/fetcher';

export const clientSwrKey = '/api/base/client';

const headers = {
    'X-API-VERSION': 'v6',
};

export function useGetClient() {
    const { data, ...props } = useSWR<ClientResponse, ErrorConstructor>(
        clientSwrKey,
        (url: string) => fetcher<ClientResponse>(url, { headers }),
    );

    return {
        client: data?.data ?? null,
        ...props,
    };
}
