'use client';

import { useParams, usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { useGetClient } from '@/services/use-get-client';
import { useGetSingleEvent } from '@/services/use-get-single-event';

export const clickType =
    typeof document !== 'undefined' && document.ontouchstart ? 'touchstart' : 'click';

const useBranding = () => {
    const { client } = useGetClient();

    const pathname = usePathname();

    const isEventPage = pathname.startsWith('/event');

    const params = useParams<{ id: string }>();
    const { event } = useGetSingleEvent(params.id);

    useEffect(() => {
        // If event/client is undefined, do nothing
        if (!client && !event) {
            return;
        }

        // Primary color
        const in2eventPrimaryColor = '#0057ff';

        // Set primary color
        const fallbackColor = in2eventPrimaryColor;
        const eventColor = event?.branding.colors.accentColor;
        const clientColor = client?.branding?.colors.accentColor ?? fallbackColor;

        let primaryColor = clientColor;

        if (isEventPage && eventColor) {
            // Use event branding if id is present and event is available
            primaryColor = eventColor;
        }

        // How to get the primary color from the client and create another variation of it for the hover state
        const convertHex = (hex: string, opacity: number) => {
            const hexValue = hex.replace('#', '');
            const r = String(parseInt(hexValue.substring(0, 2), 16));
            const g = String(parseInt(hexValue.substring(2, 4), 16));
            const b = String(parseInt(hexValue.substring(4, 6), 16));

            return `rgba(${r}, ${g}, ${b}, ${String(opacity)})`;
        };

        document.documentElement.style.setProperty('--primary-600', primaryColor);
        document.documentElement.style.setProperty('--primary-700', convertHex(primaryColor, 0.8));

        return () => {
            document.documentElement.style.removeProperty('--primary-600');
            document.documentElement.style.removeProperty('--primary-700');
        };
    }, [client, event, isEventPage]);
};

export default useBranding;
