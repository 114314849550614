import useSWRImmutable from 'swr/immutable';

import { fetcher } from '@/lib/fetcher';
import { SingleEventResponse } from '@/types/event';

export const singleEventSwrKey = (eventId: number | string | undefined) =>
    `/api/base/portals/staff/events/${eventId}`;

const headers = {
    'X-API-VERSION': 'v6',
};

export function useGetSingleEvent(eventId: number | string | undefined) {
    const key = eventId ? singleEventSwrKey(eventId) : null;

    const { data, ...props } = useSWRImmutable<SingleEventResponse, ErrorConstructor>(
        key,
        (url: string) => fetcher<SingleEventResponse>(url, { headers }),
    );

    return {
        event: data?.data ?? null,
        ...props,
    };
}
